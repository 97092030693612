import React from 'react';
import { List, Map } from 'immutable';
import { ImmutableStateMap, User } from '@interfaces';
import { RCATable, QuickLaunchSelect } from 'components';
import { useOrganization } from 'containers';
import { useInitializeSocket } from 'hooks';
import { Add24 } from '@carbon/icons-react';
import { publishAnalysis } from 'Sync';
import { sortBy } from 'lodash';
import { Button } from './Buttons';
import { Page } from './Page';

export const Analyses: React.FC<{
  dispatch: React.Dispatch<Map<string, unknown>>;
  state: ImmutableStateMap;
}> = ({ state, dispatch }) => {
  const onInitialize = React.useCallback(() => {
    dispatch(Map({ type: 'LIST' }));
    dispatch(Map({ type: 'GET_ORGANIZATION' }));
    dispatch(Map({ type: 'LIST_GROUPS' }));
  }, [dispatch]);

  const { isInitializing } = useInitializeSocket({
    socket: state.get('ws'),
    dispatch,
    onInitialize,
    onError: () => null,
  });

  const { setOrganization } = useOrganization();

  //#region state / data requirements
  // const currentSearchQuery = state.get('searchQuery');
  const token = state.get('token');
  const immutableUsers = state.get('users');
  const currentUser = state.get('username');
  const isAdmin = state.getIn(['users', currentUser, 'admin'], false);
  const groups = state.get('groups', List());
  const allTrees = state.get('trees', Map());
  const trees = allTrees
    ? allTrees
        // @ts-ignore
        .filter(tree => !tree.get('deletedAt') || tree.get('deletedAt') === null) // do not show deleted trees
    : Map();

  const owners = React.useMemo(() => {
    if (immutableUsers) {
      // @ts-ignore
      const usersArray = Object.values<User>(immutableUsers.toJS()).filter(u => !!u.fullName && u.fullName !== '');

      return usersArray
        .sort((a, b) => {
          if (!a.fullName || !b.fullName) return 0;

          const lowerAName = a.fullName.toLowerCase();
          const lowerBName = b.fullName.toLowerCase();
          if (lowerAName < lowerBName) {
            return -1;
          }

          if (lowerAName > lowerBName) {
            return 1;
          }

          return 0;
        })
        .map(u => ({
          fullName: u.fullName,
          username: u.username,
        }));
    }

    return [];
  }, [immutableUsers]);

  const handleUpdatePublishedStatus = ({ treeUuid, isPublished }) => {
    return publishAnalysis({ token, isPublished, treeUuid })
      .then(r => console.info('publish results:', r))
      .catch(error => console.error(error));
  };

  const searchQuery = state.get('searchQuery') as string;
  const lastSearch = React.useRef();
  const searchResultsIds = state.get('searchResultsTreeUuids', List());
  const currentUserRoles = state.get('role', []);

  //#region event handlers
  const handleGlobalSearch = React.useCallback(
    q => {
      // input has been cleared, remove query and results from state
      if (!q || q === '') {
        dispatch(Map({ type: 'CLEAR_SEARCH' }));
      }

      // // search query is new/not previously executed on
      if (q !== lastSearch.current) {
        lastSearch.current = q;
        return dispatch(Map({ type: 'SEARCH_NODES', query: q }));
      }

      // query has already been searched on
      return dispatch(Map({ type: 'SEARCH_NODES', query: q }));
    },
    [dispatch],
  );

  const handleClearFilters = React.useCallback(() => {
    dispatch(Map({ type: 'CLEAR_RCA_LIST_FILTER' }));
  }, [dispatch]);
  const handleSelectOwner = React.useCallback(
    o => dispatch(Map({ type: 'APPLY_RCA_LIST_FILTER', filter: 'owner', value: o })),
    [dispatch],
  );
  const handleSelectStatus = React.useCallback(
    s => dispatch(Map({ type: 'APPLY_RCA_LIST_FILTER', filter: 'status', value: s })),
    [dispatch],
  );
  const handleSelectType = React.useCallback(
    t => dispatch(Map({ type: 'APPLY_RCA_LIST_FILTER', filter: 'type', value: t })),
    [dispatch],
  );
  /* #endregion */

  const currentDataSet = React.useMemo(() => {
    if (!searchQuery) {
      return trees;
    }

    const filteredTrees = trees
      .filter(tree => {
        // @ts-ignore
        return searchResultsIds.has(tree.get('treeUuid'));
      })
      .map(tree =>
        // @ts-ignore
        tree.set('score', searchResultsIds.get(tree.get('treeUuid'))),
      );

    return filteredTrees;
  }, [searchQuery, trees, searchResultsIds]);

  const users = state.get('users');
  const i18n = state.get('i18n');

  React.useEffect(() => {
    // @ts-ignore
    if (users && users.size > 0) {
      // @ts-ignore
      const usersMap = users.toJS();
      return setOrganization(usersMap);
    }
  }, [users, setOrganization]);

  return (
    <Page
      state={state}
      id="analysis-list"
      dispatch={dispatch}
      title="RCAs"
      loading={isInitializing || !state.get('trees') || !currentUserRoles}
    >
      <div className="flex items-stretch justify-start pb4">
        {/* @ts-ignore legacy button component with invalid required props */}
        <Button
          small
          className="i18n w5 mr3"
          icon={<Add24 className="white fill--white" />}
          text="RCA Wizard"
          disabled={false}
          onClick={() => dispatch(Map({ type: 'SET_URL', url: '/new-analysis' }))}
        />
        <QuickLaunchSelect
          // @ts-ignore
          i18n={i18n}
          onChange={option => {
            return dispatch(
              Map({
                type: 'NEW_TREE',
                tree: Map({ methodology: option?.value }),
                events: List(),
              }),
            );
          }}
        />
        <div className="flex items-center justify-end ml-auto w-75">
          <button
            className="unstyled underline fw-light ph0 pv1 fs-xs"
            onClick={() => dispatch(Map({ type: 'GET_RCAS_CSV' }))}
          >
            <span className="i18n">Download RCA CSV</span>
          </button>
        </div>
      </div>

      {trees ? (
        <RCATable
          // @ts-ignore
          token={token}
          // @ts-ignore
          currentUser={currentUser}
          // @ts-ignore
          currentUserRoles={currentUserRoles}
          i18n={i18n}
          onSelectOwner={handleSelectOwner}
          onClearFilter={handleClearFilters}
          onSelectStatus={handleSelectStatus}
          onSelectType={handleSelectType}
          onSearch={handleGlobalSearch}
          onPublish={handleUpdatePublishedStatus}
          dispatch={dispatch}
          owners={owners}
          // @ts-ignore
          totalResults={searchResultsIds.size}
          searchQuery={searchQuery}
          currentFilterType={state.getIn(['rcaListFilter', 'type'], null)}
          currentFilterValue={state.getIn(['rcaListFilter', 'value'], null)}
          // data={matchingTrees.size > 0 ? matchingTrees : trees}
          data={currentDataSet}
          isLoading={isInitializing || !state.get('trees') || !currentUserRoles}
        />
      ) : null}
    </Page>
  );
};
